<template>
  <ion-img class="avatar-img" :src="user?.profilePhoto || defaultAvatar"></ion-img>
  <div class="menu-user">
    <ion-label class="fullname-text">{{ fullname }}</ion-label
    ><br />
    <ion-button class="edit-profile-btn" shape="round" @click="gotoEditProfile">
      Edit Profile
      <ion-icon :icon="chevronForwardOutline"></ion-icon>
    </ion-button>
  </div>
</template>
<script setup lang="ts">
import { defineEmits, computed } from "vue";
import { IonButton, IonImg, IonLabel, IonIcon } from "@ionic/vue";
import { useRouter } from "vue-router";
import { chevronForwardOutline } from "ionicons/icons";
import { useUserStore } from "@/store/user.store";
import defaultAvatar from "@/assets/images/avatar.png";

const router = useRouter();
const emit = defineEmits(["onClose"]);
const userStore = useUserStore();
const user = computed(() => userStore.user);
const fullname = computed(() =>
  user.value?.firstName || user.value?.lastName
    ? `${user.value?.firstName || ""} ${user.value?.lastName || ""}`
    : ""
);

const gotoEditProfile = () => {
  emit("onClose");
  router.push("/edit-profile");
};
</script>
<style lang="scss" scoped>
@import "../theme/variables/typography";
.menu-user {
  margin-left: 20px;

  .fullname-text {
    @include body-semibold;
    text-decoration: underline;
    color: #355e3b;
  }
  .edit-profile-btn {
    width: 100px;
    height: 24px;
    --padding-start: 0px;
    --padding-end: 0px;
    --border-radius: 99px;
    @include tiny-semibold;
  }
}
</style>
