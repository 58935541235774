<template>
  <ion-icon class="avatar-img" :icon="personCircleSharp"></ion-icon>
  <div>
    <ion-text class="auth-link" @click="goToLogin"> Login </ion-text>
    <ion-text> or </ion-text>
    <ion-text class="auth-link" @click="goToSingup"> Register </ion-text>
  </div>
</template>
<script setup lang="ts">
import { defineEmits } from "vue";
import { IonIcon, IonText } from "@ionic/vue";
import { useRouter } from "vue-router";
import { personCircleSharp } from "ionicons/icons";

const router = useRouter();
const emit = defineEmits(["onClose"]);
const goToLogin = () => {
  emit("onClose");
  router.push("/login");
};

const goToSingup = () => {
  emit("onClose");
  router.push("/signup");
};
</script>
<style lang="scss" scoped>
@import "../theme/variables/typography";
.avatar-img {
  color: #6a9e6d;
}
div {
  margin-left: 24px;
  ion-text {
    @include body-semibold;
    color: #161616;

    &.auth-link {
      color: #e97002;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
