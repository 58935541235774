import gql from "graphql-tag";

export const GETUSERDETAIL = gql`
query user($id: String!) {
  user(id: $id) {
    id name firstName lastName nickName email
    edrivenScoreId dob
    twitter instagram
    hometown homeProvince
    profilePhoto
    currentMetro { id name }
    homeMetro { id, name }
    homeCountry { id, name }
    favorites {
      id
      objectType
      objectId
    }
  }
}
`;

export const CHECK_TOKEN = gql`
  query checkToken($token: String!) {
    checkToken(token: $token) {
      status token user {
        id name firstName lastName nickName email
        edrivenScoreId dob
        twitter instagram
        hometown homeProvince
        profilePhoto
        currentMetro { id name }
        homeMetro { id }
        homeCountry { id }
        favorites {
          id
          objectType
          objectId
        }
      }
    }
  }
`;

export const FAVORITES_USERID = gql`
  query favorites($userId: String!) {
    favorites(userId: $userId) {
      id
      objectType
      objectId
      league {
        id
        name
        logo
      }
      organization {
        id
        name
        logo
        type
      }
      team {
        id
        name
        logo
        sport {
          name
        }
      }
      player {
        id
        name
        user {
          profilePhoto
        }
      }
    }
  }
`;

export const CREATE_FAVORITE = gql`
  mutation createFavorite($data: FavoriteInput!) {
    createFavorite(data: $data) {
      id
    }
  }
`;

export const DELETE_FAVORITE = gql`
  mutation deleteFavorite($data: FavoriteInput!) {
    deleteFavorite(data: $data)
  }
`;
export const USERS_BY_ESCOREID_QUERY = gql`
  query userScoreCards($edrivenScoreId: String!) {
    userScoreCards(edrivenScoreId: $edrivenScoreId) {     
        id
        profilePhoto
        name
        firstName
        lastName
        edrivenScoreId
    }
  }
`;