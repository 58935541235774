export const ACCESS_TOKEN = 'SCORE_ACCESS_TOKEN'


export const MENU_ITEMS = {
    firstMenu: [
      {
        title: "Home",
        url: "/home",
        icon: "fa-house",
        authRequired: false
      },
      {
        title: "Locker Room",
        url: "/locker-room",
        icon: "fa-person-to-portal",
        authRequired: true
      },
      {
        title: "Join a Team",
        url: "/join-team",
        icon: "fa-people-group",
        authRequired: false
      },
      {
        title: "Browse Organizations",
        url: "/organizations",
        icon: "fa-flag-pennant",
        authRequired: false,
      },
      {
        title: "Browse Leagues",
        url: "/leagues",
        icon: "fa-shield",
        authRequired: false,
      },
      {
        title: "Find Player",
        url: "/find-player",
        icon: "fa-user",
        authRequired: false,
      },
      {
        title: 'Following',
        url: '/following',
        icon: 'fa-bookmark',
        authRequired: true
      },
      {
        title: "Data Change Request",
        url: "/data-change-request",
        icon: "fa-pen-to-square",
        authRequired: true
      },
      {
        title: "Virtual Scoreboard",
        url: "/virtual-scoreboard",
        icon: "fa-vr-cardboard",
        authRequired: false
      },
    ],

    secondMenu: [
      {
        title: "About",
        url: "/about",
        icon: "fa-info",
        authRequired: false,
      },
      {
        title: "Advertise",
        url: "/advertise",
        icon: "fa-megaphone",
        authRequired: false,
      },
      {
        title: "Video Player",
        url: "/video-list",
        icon: "fa-video",
        authRequired: false,
      },
      // {
      //   title: "SWAG WEAR",
      //   url: "/following",
      //   icon: "fa-shirt",
      // },
    ],
  };
