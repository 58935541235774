import { defineStore } from "pinia";
import gql from 'graphql-tag';
import { Preferences } from '@capacitor/preferences';
import { apolloClient } from "@/vue-apollo";

import {
  CHECK_TOKEN,
  CREATE_FAVORITE,
  DELETE_FAVORITE,
  FAVORITES_USERID,
  GETUSERDETAIL,
  USERS_BY_ESCOREID_QUERY
} from "@/graphql/queries/userQueries";
import { UPDATEUSERPROFILE_MUTATION } from "@/graphql/mutations/userMutations";
import { signInWithApple, signInWithFacebook, signInWithGoogle, signInWithTwitter } from "@/helper/firebaseAuthLib";

export const useUserStore = defineStore("userStore", {
  state: () => {
    return {
      isLoading: false,
      profile: <any>{},
      following: <any>[],
      user: <any>{},
      users: <any[]>[],
      authenticated: false,
      authError: "",
      token: "",
      user_id:"",
      favorites: <any>[],
      anotherUser: <any>{}
    };
  },
 
  getters: {
    getIsFavorite: (state: any) => {
      const favoriteIds = (state.favorites || []).map((item: any) => item.objectId);
      return (id: string) => favoriteIds.includes(id);
    },
    getFavorites: (state: any) => {
      const favoriteLeagues = state.favorites.filter((item: any) => item.objectType === "LEAGUE");
      const favoriteOrgs = state.favorites.filter((item: any) => item.objectType === "ORGANIZATION");
      const favoriteTeams = state.favorites.filter((item: any) => item.objectType === "TEAM");
      const favoritePlayers = state.favorites.filter((item: any) => item.objectType === "PLAYER");
      const data = {
        favoriteLeagues,
        favoriteOrgs,
        favoriteTeams,
        favoritePlayers
      }
      return data;
    }
  },

  actions: {
    setUser(data: any) {
      this.user = {...this.user, ...data};
    },
    
    axUpdateUserProfile(data: any){
        console.log(data)
    },
    axCreateFollow(){
        console.log('create')
    },
    async axCheckScoreIdExists(scoreId: string)  {
      const response = await apolloClient.query({
        variables: {
          scoreId,
        },
        query: gql`
          query userScoreExists($scoreId: String!) {
            userScoreExists(edrivenScoreId: $scoreId)
          }
        `,
        fetchPolicy: 'network-only',
      })
      return response.data.userScoreExists
    },
    async emailExists(email: string) {
      const response = await apolloClient.query({
        variables: {
          email,
        },
        query: gql`
          query emailExists($email: String!) {
            emailExists(email: $email) {
              result
              msg
            }
          }
        `,
        fetchPolicy: 'network-only',
      })

      if (response.data.emailExists.result === 'success') {
        return true
      } else {
        return false
      }
    },

    async axCheckToken(initToken: string) {
      this.isLoading = true;
      try {
        const res = await apolloClient.query({
          variables: { token: initToken },
          query: CHECK_TOKEN,
          fetchPolicy: "network-only"
        });
  
        const { status, token, user } = res.data.checkToken;
        if (status !== "failed") {
          this.isLoading = false;
          if (token) {
            this.authenticated = true;
            this.token = token;
            this.user = user;
            this.user_id = user?.id;
            await Preferences.set({ key: 'accessToken', value: token })
          } else {
            this.axLogout();
          }
          return true
        } else {
          this.axLogout();
          return false;
        }
      } catch (error) {
        this.axLogout();
          return false;
      }
    },

    async axCreateOrFindFirebaseUser(data: any) {
      this.isLoading = true;
      
      const response = await apolloClient.mutate({
        variables: {
          data: {
            email: data?.email,
            displayName: data?.displayName,
            photoUrl: data?.photoUrl,
            nickName: data?.nickName,
            uid: data?.uid,
            emailVerified: data?.emailVerified,
            firstName: data?.firstName,
            lastName: data?.lastName,
            homeCountry: data?.homeCountry,
            homeMetro: data?.homeMetro,
            edrivenScoreId: data?.edrivenScoreId
          }
        },
        mutation: gql`
          mutation createOrFindFirebaseUser($data: FirebaseUserInput!) {
            createOrFindFirebaseUser(data: $data) {
              status token message user {
                id name firstName lastName nickName email
                edrivenScoreId dob
                twitter instagram
                hometown homeProvince
                profilePhoto
                currentMetro { id name }
                homeMetro { id name }
                homeCountry { id name }
                favorites { id objectType objectId }
              }
            }
          }
        `,
        fetchPolicy: 'network-only',
      })

      const { status, token, user } = response.data.createOrFindFirebaseUser;
      if (status !== "failed") {
        this.isLoading = false;
        if (token) {
          this.authenticated = true;
          this.token = token;
          this.user = user;
          this.user_id = user?.id;
          await Preferences.set({ key: 'accessToken', value: token })
        } else {
          this.axLogout();
        }
        return true
      } else {
        this.axLogout();
        return false
      }
    },

    async axGoogleLogin() {
      this.isLoading = false;
      try {
        const res: any = await signInWithGoogle();
        if (res?.uid) {
          const response: any = await this.axCreateOrFindFirebaseUser(res);
          if (response) {
            return true;
          } else {
            return false;
          }

        } else {
          this.axLogout();
        }
      } catch (error) {
        this.axLogout();
      }
    },

    async axAppleLogin() {
      this.isLoading = false;
      try {
        const res: any = await signInWithApple();
        if (res?.uid) {
          const response: any = await this.axCreateOrFindFirebaseUser(res);
          if (response) {
            return true;
          } else {
            return false;
          }

        } else {
          this.axLogout();
        }
      } catch (error) {
        this.axLogout();
      }
    },


    async axFacebookLogin() {
      this.isLoading = false;
      try {
        const res: any = await signInWithFacebook();
        if (res?.uid) {
          const response: any = await this.axCreateOrFindFirebaseUser(res);
          if (response) {
            return true;
          } else {
            return false;
          }

        } else {
          this.axLogout();
        }
      } catch (error) {
        this.axLogout();
      }
    },

    async axTwitterLogin() {
      this.isLoading = false;
      try {
        const res: any = await signInWithTwitter();
        if (res?.uid) {
          const response: any = await this.axCreateOrFindFirebaseUser(res);
          if (response) {
            return true;
          } else {
            return false;
          }

        } else {
          this.axLogout();
        }
      } catch (error) {
        this.axLogout();
      }
    },

    async axLogout(){
      this.isLoading=true
      await Preferences.remove({ key: "accessToken" });
      this.authenticated = false;
      this.isLoading = false;
      this.token = "";
      this.user = null;
      this.user_id = "";
    },

    async axGetToken() {
      try {
        const { value } = await Preferences.get({ key: 'accessToken' });
        this.token = value || "";
      } catch (error) {
        this.token = "";
        await Preferences.remove({ key: "accessToken" });
      }
    },

    async axUser(id:any) {
      this.isLoading = true;
      const res = await apolloClient.query({
        variables: { id },
        query: GETUSERDETAIL,
        fetchPolicy: "network-only"
      });

      this.isLoading = false;
      if (res.data.user && res.data.user?.id) {
        this.anotherUser = res.data.user;
      } else {
        this.anotherUser = null;
      }
    },


    async updateUser(updateUserId: string, data: any) {
      this.isLoading = true;
      const res: any = await apolloClient.mutate({
        variables: { updateUserId, data },
        mutation: UPDATEUSERPROFILE_MUTATION,
        fetchPolicy: "network-only"
      });

      this.isLoading = false;
      if (res.data?.updateUser === "success") {
        await this.axUser(updateUserId)
        return true;
      } else {
        return false;
      }
    },

    async axFavoritesByUser( userId: string ) {
      this.isLoading = true;
      const res = await apolloClient.query({
        variables: { userId },
        query: FAVORITES_USERID,
        fetchPolicy: "network-only"
      })
      this.isLoading = false;
      if (res.data?.favorites) {
        this.favorites = res.data?.favorites;
      } else {
        this.favorites = [];
      }
    },

    async axCreateFavorite(data: any) {
      this.isLoading = true;
      try {
        const res = await apolloClient.mutate({
          variables: { data },
          mutation: CREATE_FAVORITE
        });
        this.isLoading = false;
        if (res.data?.createFavorite?.id) return true
        else return false;
      } catch (error) {
        this.isLoading = false;
        return false;
      }
    },

    async axDeleteFavorite(data: any) {
      this.isLoading = true;
      try {
        const res = await apolloClient.mutate({
          variables: { data },
          mutation: DELETE_FAVORITE
        });
        this.isLoading = false;
        if (res.data?.deleteFavorite === "success") return true
        else return false;
      } catch (error) {
        this.isLoading = false;
        return false;
      }
    },
    async axUsersByEscoreId(edrivenScoreId: string) {
      this.isLoading = true;
      const res = await apolloClient.query({
        variables: { edrivenScoreId },
        query: USERS_BY_ESCOREID_QUERY,
        fetchPolicy: "network-only"
      });
      this.isLoading = false;
      if (res.data.userScoreCards) {
        this.users = res.data.userScoreCards;
        return true;
      } else {
        this.users = [];
        return false;
      }
    },
  }


})