import { ref } from 'vue';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Capacitor } from '@capacitor/core';
import { getApp, initializeApp } from "firebase/app";
import { getAuth, initializeAuth, indexedDBLocalPersistence, GoogleAuthProvider, signInWithCredential } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
//   authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
//   appId: process.env.VUE_APP_FIREBASE_APP_ID
// }

const firebaseConfig = {
  apiKey: "AIzaSyBE_pbY8fSwxik4tZGojAsocBJiJRxm-aQ",
  authDomain: "edrivenscoreboard.firebaseapp.com",
  databaseURL: "https://edrivenscoreboard.firebaseio.com",
  projectId: "edrivenscoreboard",
  storageBucket: "edrivenscoreboard.appspot.com",
  messagingSenderId: "1020916545332",
  appId: "1:1020916545332:web:307978fea2953480461761"
};

export const getFirebaseAuth = async () => {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(getApp(), {
      persistence: indexedDBLocalPersistence
    });
  } else {
    return getAuth();
  }
}

export const useFirebaseService = () => {
  const initialized = ref(false);
  !Capacitor.isNativePlatform() && FirebaseAuthentication.addListener("authStateChange", async (result) => {
    if (result.user) {
      console.log("js sdk user", await getAuth().currentUser);
    } else {
      console.log("no user found");
    }
    initialized.value = true;
  })
  
  // initialize the Firebase JS SDK
  const app = initializeApp(firebaseConfig);

  let auth: any = null;

  // if on native platform, we need to get the user from JS SDK when it fires
  // it's authStateChanged event, and get the auth object differently.
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    });

    // this is a hack that works on native devices since the plugin
    // doesn't fire the authStateChange event
    auth.onAuthStateChanged(async (user: any) => {
      let _user = user;
      if (!user) {
        _user = await FirebaseAuthentication.getCurrentUser();
        console.log("calling native API for user, you are not logged into JS SDK", _user);
        initialized.value = true;
        return;
      }
      console.log("javascript user - onAuthStateChanged", user);
      initialized.value = true;
    })
  } else {
    // if not native platform, just return auth object
    auth = getAuth(app);
  }

  return {
    auth,
    app,
    initialized,
  }
}

export const applyActionCode = async () => {
    await FirebaseAuthentication.applyActionCode({ oobCode: '1234' });
  };
  
  export const createUserWithEmailAndPassword = async (email: string, password: string) => {
    const result = await FirebaseAuthentication.createUserWithEmailAndPassword({
      email,
      password,
    });
    return result.user;
  };
  
  export const confirmPasswordReset = async () => {
    await FirebaseAuthentication.confirmPasswordReset({
      oobCode: '1234',
      newPassword: '4321',
    });
  };
  
  export const getCurrentUser = async () => {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result.user;
  };
  
  export const getIdToken = async () => {
    const currentUser = getCurrentUser();
    if (!currentUser) {
      return;
    }
    const result = await FirebaseAuthentication.getIdToken();
    return result.token;
  };
  
  export const sendEmailVerification = async () => {
    const currentUser = getCurrentUser();
    if (!currentUser) {
      return;
    }
    await FirebaseAuthentication.sendEmailVerification();
  };
  
  export const sendPasswordResetEmail = async (email: string) => {
    await FirebaseAuthentication.sendPasswordResetEmail({
      email,
    });
  };
  

  
  export const setLanguageCode = async () => {
    await FirebaseAuthentication.setLanguageCode({ languageCode: 'en-US' });
  };
  
  export const signInAnonymously = async () => {
    const result = await FirebaseAuthentication.signInAnonymously();
    return result.user;
  };
  
  export const signInWithApple = async () => {
    const result = await FirebaseAuthentication.signInWithApple();
    return result.user;
  };
  
  export const signInWithGameCenter = async () => {
    const result = await FirebaseAuthentication.signInWithGameCenter();
    return result.user;
  };
  
  export const signInWithCustomToken = async () => {
    const result = await FirebaseAuthentication.signInWithCustomToken({
      token: '1234',
    });
    return result.user;
  };
  
  export const signInWithEmailAndPassword = async (email: string, password: string) => {
    const result = await FirebaseAuthentication.signInWithEmailAndPassword({
      email,
      password,
    });
    return result.user;
  };
  

  export const signInWithFacebook = async () => {
    console.log("== FB Login 01==");
    try {
      const result = await FirebaseAuthentication.signInWithFacebook();
      console.log("result-FB==> ", result);
      return result.user;
    } catch (error) {
      console.log("errFB==>", error);
    }
  };
  
  export const signInWithGithub = async () => {
    const result = await FirebaseAuthentication.signInWithGithub();
    return result.user;
  };
  
  export const signInWithGoogle = async () => {
    // const result = await FirebaseAuthentication.signInWithGoogle();
    // return result.user;
    // 1. Create credentials on the native layer
    try {
      const result = await FirebaseAuthentication.signInWithGoogle();
      // 2. Sign in on the web layer using the id token
      const credential = GoogleAuthProvider.credential(result.credential?.idToken);
      const auth = getAuth();
      const res = await signInWithCredential(auth, credential);
      return result.user;
      
    } catch (error) {
      console.log("errorLib==>", error);
    }
  };
  
  export const signInWithMicrosoft = async () => {
    const result = await FirebaseAuthentication.signInWithMicrosoft();
    return result.user;
  };
  
  export const signInWithPlayGames = async () => {
    const result = await FirebaseAuthentication.signInWithPlayGames();
    return result.user;
  };
  
  
  export const signInWithTwitter = async () => {
    const result = await FirebaseAuthentication.signInWithTwitter();
    return result.user;
  };
  
  export const signInWithYahoo = async () => {
    const result = await FirebaseAuthentication.signInWithYahoo();
    return result.user;
  };
  
  export const signOut = async () => {
    await FirebaseAuthentication.signOut();
  };
  
  export const updateEmail = async () => {
    const currentUser = getCurrentUser();
    if (!currentUser) {
      return;
    }
    await FirebaseAuthentication.updateEmail({
      newEmail: 'new.mail@example.com',
    });
  };
  
  export const updatePassword = async () => {
    const currentUser = getCurrentUser();
    if (!currentUser) {
      return;
    }
    await FirebaseAuthentication.updatePassword({
      newPassword: '4321',
    });
  };
  
  export const useAppLanguage = async () => {
    await FirebaseAuthentication.useAppLanguage();
  };
  
  export const useEmulator = async () => {
    await FirebaseAuthentication.useEmulator({
      host: '10.0.2.2',
      port: 9099,
    });
  };