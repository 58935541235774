import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import { Preferences } from "@capacitor/preferences";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "SignIn",
    component: () => import("../views/Auth/LoginPage.vue"),
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    component: () => import("../views/User/EditProfile.vue"),
    meta: { auth: true },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("../views/Auth/SignupPage.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/Auth/ForgotPassword.vue"),
  },
  {
    path: "/signup/set-location",
    name: "SetLocation",
    component: () => import("../views/Auth/SetLocationPage.vue"),
  },
  {
    path: "/signup/verify-name",
    name: "VerifyName",
    component: () => import("../views/Auth/VerifyNamePage.vue"),
  },
  {
    path: "/signup/create-password",
    name: "CreatePassword",
    component: () => import("../views/Auth/CreatePasswordPage.vue"),
  },
  {
    path: "/signup/success",
    name: "SignupSuccess",
    component: () => import("../views/Auth/SignupSuccessPage.vue"),
  },
  {
    path: "/home",
    name: "Homepage",
    component: () => import("../views/HomePage.vue"),
  },
  {
    path: "/locker-room",
    name: "Locker Room",
    component: () => import("../views/LockerRoom/LockerRoomPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/scoreboard-details/:scoreboardId",
    name: "Details Match",
    component: () => import("../views/Scoreboard/ScoreboardDetail.vue"),
  },
  {
    path: "/leagues",
    name: "Leagues",
    component: () => import("../views/Leagues/LeaguesPage.vue"),
  },
  {
    path: "/league-details/:league_id",
    name: "LeaguesDetails",
    component: () => import("../views/Leagues/LeagueDetailsPage.vue"),
  },
  {
    path: "/organizations",
    name: "Organizations",
    component: () => import("../views/Organizations/OrganizationsPage.vue"),
  },
  {
    path: "/organization-details/:organization_id",
    name: "OrganizationDetails",
    component: () =>
      import("../views/Organizations/OrganizationDetailsPage.vue"),
  },
  {
    path: "/teams",
    name: "Teams",
    component: () => import("../views/Teams/TeamsPage.vue"),
  },
  {
    path: "/team-details/:team_id",
    name: "TeamDetails",
    component: () => import("../views/Teams/TeamDetailsPage.vue"),
  },
  {
    path: "/player-cards/",
    name: "Player list",
    component: () => import("../views/LockerRoom/PlayerCardsPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/player-contracts/",
    name: "My Contracts",
    component: () => import("../views/LockerRoom/PlayerContractsPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/player-contract/:playerId/",
    name: "Contract Detail",
    component: () => import("../views/LockerRoom/PlayerContractDetailPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/player-card/:playerId",
    name: "Player Card",
    component: () => import("../views/LockerRoom/PlayerCardPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/athlete-details/:id",
    name: "Athlete Details",
    component: () => import("../views/Players/PlayerDetailPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/join-team",
    name: "Join Team",
    component: () => import("../views/LockerRoom/JoinTeamPage.vue"),
  },
  {
    path: "/find-player",
    name: "Find Players",
    component: () => import("../views/Players/FindPlayersPage.vue"),
  },
  {
    path: "/scorecard/:scoreCarId",
    name: "ScoreCardDetails",
    component: () => import("../views/Scorecard/ScoreCard.vue"),
  },
  {
    path: "/trophy",
    name: "Trophy Room",
    component: () => import("../views/LockerRoom/TrophyRoomPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/youth-cards",
    name: "Youth Card",
    component: () => import("../views/LockerRoom/YouthCardPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/claims",
    name: "Claims Station",
    component: () => import("../views/LockerRoom/ClaimsStationPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/staffs",
    name: "Staff Cards",
    component: () => import("../views/LockerRoom/StaffCardsPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/staff-details/:staffId",
    name: "Staff Information",
    component: () => import("../views/LockerRoom/StaffDetailPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/manager",
    name: "Manager Room",
    component: () => import("../views/LockerRoom/ManagerRoomPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/coaches",
    name: "Coaches Office (Help)",
    component: () => import("../views/LockerRoom/CoachesOfficePage.vue"),
    meta: { auth: true },
  },
  {
    path: "/following",
    name: "Following",
    component: () => import("../views/Following/FollowingPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/trophynodata",
    name: "Trophy Room No Data",
    component: () => import("../views/LockerRoom/TrophyRoomPageNoData.vue"),
    meta: { auth: true },
  },
  {
    path: "/suggestion-box",
    name: "Suggestion Box ",
    component: () => import("../views/LockerRoom/SuggestionBoxPage.vue"),
  },

  {
    path: "/about",
    name: "About",
    component: () => import("../views/About/AboutPage.vue"),
  },
  {
    path: "/advertise",
    name: "Advertise",
    component: () => import("../views/Advertise/AdvertisePage.vue"),
  },
  {
    path: "/video-list",
    name: "Video Player",
    component: () => import("../views/VideoPlayer/VideoPlayer.vue"),
  },
  {
    path: "/data-change-request",
    name: "Data Change Request",
    component: () => import("../views/DataChangeRequest/IndexPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/data-change-request/:id",
    name: "Request Details",
    component: () => import("../views/DataChangeRequest/DetailsPage.vue"),
    meta: { auth: true },
  },
  {
    path: "/virtual-scoreboard",
    name: "Virtual Scoreboard",
    component: () => import("../views/VirtualScoreboard/VirtualScoreboard.vue"),
    meta: { auth: false },
  },
  {
    path: "/virtual-scoreboard/create",
    name: "Create Scoreboard",
    component: () =>
      import("../views/VirtualScoreboard/CreateVirtualScoreboard.vue"),
    meta: { auth: true },
  },
  {
    path: "/virtual-scoreboard/:id/edit",
    name: "Game Scoreboard",
    component: () =>
      import("../views/VirtualScoreboard/EditVirtualScoreboard.vue"),
    meta: { auth: true },
  },
  {
    path: "/virtual-scoreboard/:id/view",
    name: "Game Scoreboard Detail",
    component: () =>
      import("../views/VirtualScoreboard/VirtualScoreboardDetail.vue"),
    meta: { auth: false },
  },
  {
    path: "/virtual-scoreboard/join",
    name: "Join Game",
    component: () =>
      import("../views/VirtualScoreboard/JoinVirtualScoreboard.vue"),
    meta: { auth: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // document.title = `${to.meta.title} - ${process.env.VUE_APP_NAME}`;
  const { value } = await Preferences.get({ key: "accessToken" });
  if (value) {
    if (to.path === "/login" || to.path === "/signup") {
      return next({ name: "Homepage" });
    } else {
      return next();
    }
  } else {
    if (to?.meta && to?.meta?.auth) {
      return next({ name: "Homepage" });
    } else {
      return next();
    }
  }
});

export default router;
