import { ToastOptions, toastController } from "@ionic/vue";
import { differenceInYears, parse } from "date-fns";

export const presentToast = async (message: string, color = "danger", time = 3500, position = "top") => {
    const toast = await toastController.create({
      message,
      color,
      duration: time,
      position : position as ToastOptions["position"],
    });
    toast.present();
  };

export const containsUppercase = (letters: string) => {
  return /^[A-Z.]+$/.test(letters);
};

export const calculateAge = (dob: string): number => {
  const date = parse(dob, "yyyy-MM-dd", new Date());
  const age = differenceInYears(new Date(), date);
  return age;
}