import { createApp, provide, h, watch } from "vue";
import App from "./App.vue";
import router from "./router";
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// Initialize Firebase

import { IonicVue } from "@ionic/vue";
import { defineCustomElements } from '@ionic/pwa-elements/loader';

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import {
  DefaultApolloClient,
  provideApolloClient,
} from "@vue/apollo-composable";
import { createPinia } from "pinia";
import { apolloClient } from "./vue-apollo";
import VueDatePicker from "@vuepic/vue-datepicker";
import Vue3Signature from "vue3-signature"

/* Theme variables */
import '@vuepic/vue-datepicker/dist/main.css';
import "./theme/variables.css";
import "@/theme/global.scss";
import 'animate.css'
import { useFirebaseService } from "./helper/firebaseAuthLib";

const pinia = createPinia();
provideApolloClient(apolloClient);

const { initialized } = useFirebaseService();

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
})
  .component('VueDatePicker', VueDatePicker)
  .use(IonicVue)
 // .use(Vue3Signature)
  .use(router)
  .use(pinia);

watch(initialized, (value) => {
  console.log({ initialized: value });
  //mount the app
  console.log("mounting the app...");
  router.isReady().then(() => {
    app.mount("#app");
    defineCustomElements(window)
  });
});
